import React, { createContext, Component } from 'react';
import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';
import closeIcon from '../images/close-icon.svg';
import MEDIA from '../styles/media';

const ModalContext = createContext();

export const ModalConsumer = ModalContext.Consumer;

export default class ModalProvider extends Component {
  showModal = (content, props = {}) => {
    this.setState({
      content,
      props,
    });
  };

  hideModal = e => {
    e.stopPropagation();
    this.setState({
      content: null,
      props: {},
    });
  };

  state = {
    content: null,
    props: {},
    showModal: this.showModal.bind(this),
    hideModal: this.hideModal.bind(this),
  };

  render() {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
        <Modal content={this.state.content} hideModal={this.hideModal} />
      </ModalContext.Provider>
    );
  }
}

const Modal = props => {
  const Content = props.content;
  return (
    <PoseGroup>
      {props.content && (
        <ModalContainer key="modal-key" onClick={props.hideModal}>
          <ModalContent onClick={e => e.stopPropagation()}>
            <Content />
          </ModalContent>
          <CloseButton onClick={props.hideModal}>
            <img src={closeIcon} alt="Close Icon" />
          </CloseButton>
        </ModalContainer>
      )}
    </PoseGroup>
  );
};

const ModalContainer = styled(
  posed.div({
    enter: {
      opacity: 1,
      transition: { duration: 150, ease: 'easeIn' },
    },
    exit: {
      opacity: 0,
      transition: { duration: 150, ease: 'easeIn' },
      flip: true
    },
  })
)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const ModalContent = styled(
  posed.div({
    enter: {
      translateY: 0,
      transition: { duration: 250, ease: 'easeIn' },
      beforeChildren: true,
    },
    exit: {
      translateY: '1%',
      transition: { duration: 150, ease: 'easeIn' },
      flip: true,
    },
  })
)`
  margin: 2rem;
  ${MEDIA.PHONE`margin:1rem;`};
  flex: 1;
  text-align: center;
`;

const CloseButton = styled.button`
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;
